import React from 'react';

function PolicyTitle(props) {
  return (
    <div className="banner -legal">
      <div className="chip">Ventures</div>
      <h1>Privacy Policy</h1>
    </div>
  );
}

export default PolicyTitle;