import React from 'react';
import Layout from '../components/layout/Layout';
import PolicyIntro from '../components/privacy-policy/PolicyIntro';
import PolicyList from '../components/privacy-policy/PolicyList';
import PolicyTitle from '../components/privacy-policy/PolicyTitle';

function PrivacyPolicy() {
  return (
    <Layout
      title="Lawyer like never before"
      description="because status quo no longer works"
      image="/metaimages/meta-image-la-ventures.png"
    >
      <div className="fit-width page-legal">
        <PolicyTitle />
        <PolicyIntro />
        <PolicyList />
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
