import React from 'react';

function PolicyIntro(props) {
  return (
    <p className="intro">
      This privacy policy applies between you, the User of this Website and LawAdvisor Ventures Ltd,
       the owner and provider of this Website. LawAdvisor Ventures takes the
      privacy of your information very seriously. This privacy policy applies to our use of any and
      all Data collected by us or provided by you in relation to your use of the Website.
      <br />
      <b>Please read this privacy policy carefully.</b>
    </p>
  );
}

export default PolicyIntro;